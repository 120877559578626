import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import WebappFooter from "./webappfooter";
import consulting from '../images/consulting.png';
import mobileappdevelopment from '../images/mobileappdevelopment.png';
import webdevelopment from '../images/webdevelopment.png';
import websitehosting from '../images/websitehosting.png';

const featureList = [
    {
      id: 1,
      img: mobileappdevelopment,
      name: 'Mobile App Development',
      featured: true,
    },
    {
        id: 2,
        img: webdevelopment,
        name: 'Website & webapp Development',
    },
    {
        id: 3,
        img: websitehosting,
        name: 'Website and API hosting',
        featured: true,
      },
      {
          id: 4,
          img: consulting,
          name: 'Consultation',
      },
  ];
const theme = createTheme();

export default function DefaultComponent() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          {/* <CameraIcon sx={{ mr: 2 }} /> */}
          <Typography variant="h6" color="inherit" noWrap>
            eVidha
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              eVidha
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
            >
                eVidha is a platform to help business to make an online presence. 
                We help businesses with custom Apps, Websites and personalised Software creation. 
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
            >
                We also provide services such as Website hosting, Web API hosting and Android app.  
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
            >
                For enquiry, please contact us at 
            </Typography>
            <Link varient="h5" color="primary" href="mailto:evidhaone@gmail.com">
                evidhaone@gmail.com
              </Link>
          </Container>
        </Box>
        <Container maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {featureList.map((feat) => (
              <Grid item key={feat.id} xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={feat.img}
                    alt={feat.name}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {feat.name}
                    </Typography>
                  </CardContent>
               
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <WebappFooter />
    </ThemeProvider>
  );
}

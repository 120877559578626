import './App.css';
import DefaultComponent from './components/defaultcoponent';

function App() {
  return (
    <div className="App">
     <DefaultComponent/>
    </div>
  );
}

export default App;
